import React, { Component ,  useEffect, useRef} from 'react'
import { useLocation , useNavigate,} from 'react-router-dom';

// import UserConfirmed from './components/messages/UserConfirmed'
function UserConfirmed(props){
    const timeoutId = useRef(null);
    const location = useLocation();
    const email = location.search.split('email=')[1];
    const navigate = useNavigate();

      useEffect(() => {
        timeoutId.current = setTimeout(() => {
          console.log('Timeout finished');
          // navigate('/');
        }, 8000);
    
        return () => {
          clearTimeout(timeoutId.current);
        }
      }, []);

    return(
        <>
            <h1>Registration Successful! </h1>
            <div className='message'>
                <h2>Check your email <span>for your qr code to checkin.</span></h2>
                <h3>We sent to {email}.</h3>
            </div>
            {/* <h3 className='note'>You will be redirected to home page in a moment.</h3> */}
        </>
    );

    
}
export default UserConfirmed;