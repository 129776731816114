import React, { Component,  useEffect, useRef  } from 'react'
import { useLocation , useNavigate} from 'react-router-dom';

// import UserConfirmed from './components/messages/UserConfirmed'
function Message(props){

    const timeoutId = useRef(null);

    const location = useLocation();
    const navHome = decodeURI(location.search.split('gohome=')[1]);
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(window.location.search);
    const message = searchParams.get('message');
    const title = searchParams.get('title');
    const redirect = searchParams.get('redirect');

    useEffect(() => {
        timeoutId.current = setTimeout(() => {
          console.log('Timeout finished');
          if(redirect!=='false'){
            navigate('/');
          }
        }, 6000);
    
        return () => {
          clearTimeout(timeoutId.current);
        }
      }, []);

    return(
        <div id="messageWindow">
            <h1>{title}</h1>
            <div id='messageContent'>{message}</div>
            {redirect !== 'false' && <p className="note">System will return to home.</p>}
        </div>
    );
}
export default Message;