export default function useUrl() {
  const hostname = window.location.hostname;
  let environment;

  if (hostname.includes('duckdns.org')) {
    environment = 'remote';
  } else if (hostname === 'localhost') {
    environment = 'development';
  } else if (hostname.startsWith('192.168.')) {
    environment = "local";
  } else {
    environment = 'production'; // Default to production for other cases
  }

  let initialUrl, wsBaseUrl, frontEndUrl;

  switch (environment) {
    case 'development':
      initialUrl = `http://${hostname}:8080/`;
      wsBaseUrl = `ws://${hostname}:81/`;
      frontEndUrl = `http://${hostname}:3000/`;
      break;

    case 'local':
      initialUrl = `http://${hostname}:8080/`;
      wsBaseUrl = `ws://${hostname}:81/`;
      frontEndUrl = `http://${hostname}:3000/`;
      break;
    
    case 'remote':
      initialUrl = `http://${hostname}:8086/`;
      wsBaseUrl = `ws://${hostname}:8086/`; // Update if needed
      frontEndUrl = `http://${hostname}:8084/`;
      break;

    default: // production
      initialUrl = 'https://checkin2.tipithailand.com/api/';
      wsBaseUrl = 'https://checkin2.tipithailand.com/api/'; // Update if needed
      frontEndUrl = '/';
      break;
  }

  return { initialUrl, wsBaseUrl, frontEndUrl };
}

// export default function useUrl() {
//   const hostname = window.location.hostname;
//   let environment;

//   if (hostname.includes('duckdns.org')) {
//     environment = 'remote';
//   } else if (hostname === 'localhost') {
//     environment = 'development';
//   } else if (hostname.includes('192.168.')) {
//     environment = "local";
//   }
//   else {
//     environment = 'production'; // Default to production for other cases
//   }

//   let initialUrl, wsBaseUrl, frontEndUrl;

//   switch (environment) {
//     case 'development':
//       initialUrl = 'http://localhost:8080/dash/api/';
//       wsBaseUrl = 'ws://localhost:81/';
//       frontEndUrl = 'http://localhost:3000/';
//       break;

//     case 'local':
//       initialUrl = 'http://192.168.1.128:8080/dash/api/';
//       wsBaseUrl = 'ws://localhost:81/';
//       frontEndUrl = 'http://192.168.1.128:3000/';
//       break;
    
//     case 'remote':
//       initialUrl = 'http://tipiskyelaptop.duckdns.org:8086/dash/api/';
//       wsBaseUrl = 'ws://tipiskyelaptop.duckdns.org:8086/'; // Update if needed
//       frontEndUrl = 'http://tipiskyelaptop.duckdns.org:8084/';
//       break;

//     default: // production
//       initialUrl = '/dash/api/';
//       wsBaseUrl = '/dash/api/'; // Update if needed
//       frontEndUrl = '/';
//       break;
//   }

//   return { initialUrl, wsBaseUrl, frontEndUrl };
// }
