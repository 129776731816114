import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import useUrl from "./UseUrl";
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import paypalQR from '../Images/paypalQR.png';
import logoimg from '../Images/tipilogo.png'
import Payment from './Payment';
import Registration from './Registration';


function Invoice(props) {
  
  const { initialUrl } = useUrl();
  const [paypalQRCode, setPaypalQRCode] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState('');
  const [uid, setUid] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [totalAmount, setTotalAmount] = useState(0); // Total amount for payment
  const [isPaid, setIsPaid] = useState(false); // New state to track if invoice is paid
  const registrationFormRef = useRef(null);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormValidityChange = (isValid) => {
    setIsFormValid(isValid);
  };

  const updateFormValidity = (validity) => {
    setIsFormValid(validity);
};



  useEffect(() => {
    // Calculate the total amount from invoice data
    if (invoiceData && invoiceData.total) {
        setTotalAmount(parseFloat(invoiceData.total));
    }
}, [invoiceData]);

  const [isPrinting, setIsPrinting] = useState(false);
  useEffect(() => {
    props.setShowHeader(false);
  
    return () => {
      // Reset to original state when the component unmounts
      props.setShowHeader(true);
    };
  }, [props]);

  // useEffect(() => {
  //   // Function to call PayPal API to generate QR code
  //   const generatePayPalQRCode = async () => {
  //       // const invoiceAmount = /* get your invoice amount here */;
  //       // Call PayPal API to generate QR code for this amount
  //       // This is a placeholder, you need to replace it with actual API call
  //       const response = await fetch(`${initialUrl}?action=makePaypalQR&amount=${totalAmount}`, {
  //           method: 'POST',
  //           body: JSON.stringify({ amount: totalAmount }),
  //           // headers: {
  //           //     'Content-Type': 'application/json'
  //           // }
  //       });

  //       if (response.ok) {
  //           const data = await response.json();
  //           setPaypalQRCode(data.qrCodeUrl); // Assuming the API returns a URL for the QR code
  //       }
  //   };

//     generatePayPalQRCode();
// }, []);
  
  function fixdate(date){
    let dt = date.split(" ")[0];
    return dt;
  }
  // useEffect(() => {
  //   const printDetector = document.getElementById('print-detector');
  //   const observer = new MutationObserver(mutations => {
  //     mutations.forEach(mutation => {
  //       if (mutation.attributeName === 'style') {
  //         setIsPrinting(printDetector.style.display === 'block');
  //       }
  //     });
  //   });

  //   observer.observe(printDetector, { attributes: true });

  //   return () => observer.disconnect(); // Cleanup observer on component unmount
  // }, []);


  // Extract date from datetime string
  function fixdate(date) {
    return date.split(" ")[0];
  }
  const downloadPDF = () => {
    const element = document.getElementById('invoice-container');
    const opt = {
      margin: [10, 10, 10, 10], // top, left, bottom, right
      filename: `Invoice-${invoiceData.id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    // alert('downlaod pdf');
    html2pdf().from(element).set(opt).save().then(() => {
      console.log('PDF download started');
    }).catch((error) => {
      console.error('Error generating PDF:', error);
    });
    
  }

  const downloadImage = async () => {
    const element = document.getElementById('invoice-container');
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const link = document.createElement('a');
    link.download = `Invoice-${invoiceData.id}.png`;
    link.href = image;
    link.click();
  }

  // Fetch ID from URL parameters
  useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const idFromURL = searchParams.get('id');
    // if (idFromURL) {
    //   setId(idFromURL);
    // }
    {props.id && setId(props.id);}
  }, []);

  const paypalBaseLink = "https://www.paypal.me/tipithailand/";

  // Function to generate PayPal link with amount
  const createPayPalLink = (amount) => {
      return `${paypalBaseLink}${amount}`;
  };


  // Fetch invoice and payment data based on ID
  useEffect(() => {
    async function fetchData() {
      if (!id) return;  // Exit if id is not set
      setIsLoading(true);

      try {
        const invoiceResponse = await fetch(`${initialUrl}?action=getInvoice&id=${id}`);
        const invoiceData = await invoiceResponse.json();
        setInvoiceData(invoiceData[0]);  // Assuming the response is a single object
        // let result = 
        setFname(invoiceData[0].fname);
        setLname(invoiceData[0].lname);
        setUid(invoiceData[0].uid);

        // Check if invoice is already paid
        if (invoiceData[0].paid === '1') { // Assuming 'status' field indicates payment status
          setIsPaid(true);
        }

        // Fetch payment details if listofpayments is available
        if (invoiceData[0] && invoiceData[0].listofpayments) {
          const paymentIds = JSON.parse(invoiceData[0].listofpayments);
          const paymentsResponse = await fetch(`${initialUrl}?action=getPaymentIds&ids=${JSON.stringify(paymentIds)}`);
          const paymentsData = await paymentsResponse.json();
          setPayments(paymentsData.data);
          console.log("payments are"+payments)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [initialUrl, id]);

  console.log("on invoice isFormValid in Invoice:", isFormValid);

  // Update page title
  useEffect(() => {
    if (!props.uid && typeof props.setheadingTitle === 'function') {
      props.setheadingTitle('Invoice');
    }
  }, [props.setheadingTitle]);

  const handleApprove = (orderId) => {
    alert('i would send to payment capture now');
    // Handle successful transaction
    // Call your backend to capture the transaction
    // Example: fetch(`${initialUrl}/capture-payment?orderID=${orderId}`);
  };

  return (
    <>
      {/* <div className="invoice-buttons">
        <button onClick={downloadPDF}><FontAwesomeIcon icon={faFilePdf} /></button>
        <button onClick={downloadImage}><FontAwesomeIcon icon={faFileImage} /></button>

         <a href=""> 

        </a>
      </div> */}

      {/* {(uid == 1) ? 'undefined user' : ('user defined ('+uid+'), it\'s: ' + fname + " " + lname)} */}
      {(uid == 1) ? (
        <>
          <h1 className='simpleHeader'>Register and Pay</h1>
          <p>Make sure you have access to your email, to get your QR code for checking in at Tipi.</p>
        </>
      ) : (
        <>
        {isPaid ? (
          <h1 className='simpleHeader'>Paid Invoice Receipt</h1>
        ) : (
          <h1 className='simpleHeader'>Make Payment</h1>
        )}
          
          {/* <p>Make sure you have access to your email, to get your QR code for checking in at Tipi.</p> */}
        </>

      )}
      <div className="invoice-page">
      
        
        
        
        {/* {(uid == 1) && <Registration isFormValid={isFormValid} updateFormValidity={props.updateFormValidity} uid={uid}/>}       */}
        {(uid == 1) && <Registration isFormValid={isFormValid} uid={uid}/>}      
        <div className="invoice-container" id="invoice-container">

          {isLoading ? (
            <p>Loading...</p>
          ) : invoiceData ? (
            <>
            
            {(uid !== '1') ? (
            <div id='invoicePayTop'>
              <div className="customerdetails notprintable">
                <h3>Customer Information</h3>
                <p><span>Name:</span> {invoiceData.fname} {invoiceData.lname}</p>
                <p><span>Email:</span> {invoiceData.email}</p>
              </div>
              <div className="invoicetotal">
                <h2>TOTAL</h2>
                {invoiceData.total} THB
              </div>
            </div>) : 
              (<div className="invoicetotal">
                <h2>TOTAL</h2>
                {invoiceData.total} THB
              </div>)
              
              }
              
            <div className="invoice-top printonly" 
            // style={{'display':'none'}}
            >
                <div className="invoice-title-area notprintable">
                  <div className="logoimg"><img src={logoimg} alt="Tipi Company Logo" /></div>
                  {/* <div class="invoicetitle"><h2>Payment Receipt</h2></div> */}
                  {/* <div class="invoicetitle"><h2>Invoice</h2></div> */}
                </div>
                <div className="invoice-title-area printonly">
                  {/* <img src="./tipilogo.png" alt="Tipi Company Logo" /> */}
                  {/* <h2>Payment Receipt Print</h2> */}
                  <h2>Invoice</h2>
                </div>
                <div className="invoice-header">
                    
                    <div className="invoicedetails">
                      <h3>Invoice Details</h3>
                      <p><span>Invoice Number:</span> {invoiceData.id}</p>
                      <p><span>Date:</span> {fixdate(invoiceData.datecreated)}</p>
                    </div>
                    
                    <div className="companydetails">
                      <h3>Company Details</h3>
                      <p className="companyname">Tipi Thailand</p>
                      <p className="address">19 Moo 5<br/>
                      Koh Phangan, Thailand 84280</p>
                    </div>

                </div>

                <div className="customerdetails">
                  <h3>Customer Information</h3>
                  <p><span>Name:</span> {invoiceData.fname} {invoiceData.lname}</p>
                  <p><span>Email:</span> {invoiceData.email}</p>
                </div>
              


            </div>   
            <div className="payment-details">
            <h3 className="printonly">Payment Details</h3>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Description</th>
                  {/* More columns as needed */}
                </tr>
              </thead>
              <tbody>
                {payments && payments.map((payment, index) => (
                  <tr key={index}>
                    <td>{fixdate(payment.date)}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.description}</td>
                    {/* More data as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          

            <div className="invoiceFooter printonly">
                  TIPITHAILAND.COM
              </div>  
          </div>
          {/* PayPal Payable Link Button */}
          {
            isPaid ? (
              <p className="paymentsuccess">Invoice is PAID!</p>
            ) : (
              uid !== 1
                ? <Payment uid={uid} isFormValid={true} setIsPaid={setIsPaid} />
                : <Payment uid={uid} isFormValid={isFormValid} setIsPaid={setIsPaid} />
            )
          }


          {/* <div className="paypal-link">
            {invoiceData && (
                <a
                    href={createPayPalLink(invoiceData.total)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="paypal-button"
                >
                    <FontAwesomeIcon icon={faPaypal} /> Pay ฿{invoiceData.total} with Debit/Credit or PayPal Here
                </a>
            )}
          </div> */}
                </>  
            
          ) : (
            <p>Invoice number not found.</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Invoice;