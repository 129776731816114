import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import chance from 'chance';
import useUrl from './UseUrl';
import Loader from './Loader';

const Registration = forwardRef((props, ref) => {
  const url = useUrl().initialUrl;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValidity] = useState(false);

  

  const checkFormValidity = () => {
    const form = document.getElementById('theform');
    const inputs = form.querySelectorAll('input, select');

    inputs.forEach(input => {
        if (!input.validity.valid) {
            input.classList.add('input-error');
        } else {
            input.classList.remove('input-error');
        }
    });

    // props.updateFormValidity(form.checkValidity());
    setFormValidity(form.checkValidity());
};

  useEffect(() => {
    // Call checkFormValidity when component mounts
    checkFormValidity();
  }, []);
  // const formRef = useRef(null);

  useEffect(() => {
    // Pass the form reference to parent component (Home)
    // props.setFormRef(formRef);
  }, []);


  const handleFormSubmit = async (event) => {
    // props.onFormValidityChange();
    checkFormValidity();
    event.preventDefault();
    setLoading(true);
    const form = event.target;
  
    if (!formValid) {
      alert("Please fill in all required fields correctly.");
      setLoading(false);
      return;
    }
  
    const userObj = {
      fname: form.fname.value,
      lname: form.lname.value,
      email: form.email.value,
      phone: form.phone.value,
      nationality: form.nationality.value,
      gender: form.gender.value,
    };
  
    // Construct query string
    const queryString = Object.entries(userObj).map(([key, val]) => 
      `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
  
    try {
      const response = await fetch(`${url}?action=newoutsideuser&${queryString}`, {
        method: 'GET'
      });
  
      const data = await response.json();
      if (data.error) {
        // alert('User already exists');
        let msg='User Agitlready exists';
        navigate(`/message?message='${msg}'`);
      } else {
        // alert("Your user was successfully created!");
        navigate(`/userconfirmed?email=${form.email.value}`);
      }
      // navigate(`/message?message=${data.message}&redirect=false`);
      
    } catch (error) {
      console.error('Error during form submission:', error);
      alert('Error during form submission');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      {props.uid !=='1' && <h1>Register For Tipi</h1>}

      {loading && <Loader message="Preparing your QR code... Please wait while we finish registration." />}
      <div id='registrationForm' style={loading ? { display: 'none' } : {}}>
        {/* <form id='theform' onSubmit={handleFormSubmit}> */}
        <form id='theform' onSubmit={handleFormSubmit} onChange={checkFormValidity}>
       
        <div class='two-columns'>
        <div class='inputwrapper'>
            <input type='text' id='fname' name='fname' placeholder='First Name' required></input>
            <input type='text' id='lname' name='lname' placeholder='Last Name' required></input>
            <input type='email' id='email' name='email' placeholder='Email Address' required></input>
        </div>
        <div class='inputwrapper'>
            <input type="tel" id="phone" name="phone" placeholder='Phone' />
            <input type='text' id='nationality' name='nationality' placeholder='Nationality' pattern="[A-Za-z\s\.\&]*"></input>
            <select id='gender' name='gender'>
                <option value="m">Male</option>
                <option value="f">Female</option>
                <option value="o">Other</option>
            </select>
        </div>
    </div>
    <div id='waiverarea'>
      <h3>WAIVER/ASSUMPTION OF RISK:</h3>
      <p>By agreeing below, I understand that the instructors and the facility (Tipi Thailand) assume no responsibility for injuries or illnesses which I may sustain as a result of my physical condition or resulting from participation in a class and/or from the use the equipment at the facility. In addition, I understand that I should consult with my physician before beginning any physical activity. I expressly acknowledge that I assume the risk for any and all injuries and illnesses, which may result from participation in any fitness or yoga class offered by Tipi Thailand. I hereby release and discharge the instructor and the facility from any and all claims for injury, illness, death, loss or damage, which may result from my participation in activities at this facility.</p>
      <p>By agreeing below, I agree that I have read and agree to the terms and conditions stated above.</p>
      <h3>COMMUNICATION</h3>
      <p>By agreeing below, you agree to receive newsletters, updates, and special offers via email.</p>
      <p>We promise to send only the most relevant and interesting content, and we will never share your information with third parties. Thank you for being a valued member of our community.</p>
      <label> <input type='checkbox' required id='agree' name='agree' default /><span>Agree</span></label>
    </div>
    {props.uid !== '1' && <button type="submit">Submit</button>}

        </form>
      </div>
    </>
  );
});

export default Registration;
