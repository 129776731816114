import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUrl from "./UseUrl";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Payment(props) {
  const { initialUrl } = useUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [orderId, setOrderId] = useState(null); // Store PayPal order ID
  const [paymentComplete, setPaymentComplete] = useState(false); // Store PayPal order ID

  const isSandbox = false;

  // PayPal client IDs
  const sandboxClientId = 'AUqpdAoJ0d7T4afQtY0QkxEh1-nftCbK4xftRGm2C8AweN7sxeUmlJgNwnqjDEjyrQuMyoIEWB3IqD-S';
  const liveClientId = 'AfXSCMgGTDGo5UXNSdVcR1FDTsRrJ054vXpixHdYvg9s77cqEvC4qwXoj612AO8MqNpGjwlWPnDHQYgD';
  
  // Select the appropriate client ID based on the environment
  const clientId = isSandbox ? sandboxClientId : liveClientId;

  const checkFormValidity = () => {
    if (props.formRef && props.formRef.current) {
      return props.formRef.current.checkValidity();
    }
    return false;
  };

  // const isFormValid = () => {
  //   if (props.isRegistrationFormValid) {
  //     return props.isRegistrationFormValid();
  //   }
  //   return false;
  // };


// Fetch ID from URL parameters
useEffect(() => {
  const searchParams = new URLSearchParams(window.location.search);
  setId(searchParams.get('id') || '');
}, []);

// Function to initiate payment
const initiatePayment = async () => {
  if (!props.isFormValid) {
      alert("Please fill in all required fields in the registration form correctly.");
      return;
  }
  if (!id) {
    console.error('Invoice ID is missing');
    return;
  }
  setIsLoading(true);
  try {
    const response = await fetch(`${initialUrl}?action=payinvoice&id=${id}`);
    const data = await response.json();
    if (data && data.orderID) {
      setOrderId(data.orderID); // Store the PayPal order ID
      return data.orderID;
    } else {
      throw new Error('Failed to create PayPal order.');
    }
  } catch (error) {
    console.error('Error initiating payment:', error);
  }
  setIsLoading(false); // Stop loading if there's an error
};

// Function to handle payment approval
const handleApprove = async (data) => {
  // Use 'data.orderID' provided by PayPal's onApprove callback
  const orderId = data.orderID;
  if (!orderId) {
    console.error('Order ID is missing');
    return;
  }

  setIsLoading(true);
  try {
    // Call your backend endpoint to capture the order
    const response = await fetch(`${initialUrl}?action=captureOrderAndMarkPaid&orderID=${orderId}&invoiceID=${id}`);
    const result = await response.json();
    if (result.error === 'none') {
      // alert('Payment successful!');
      setPaymentComplete(true);
      props.setIsPaid(true);
      // Additional logic after successful payment, like redirecting to a success page
    } else {
      throw new Error('Payment capture failed.');
    }
  } catch (error) {
    console.error('Error capturing payment:', error);
  } finally {
    setIsLoading(false);
  }
  setIsLoading(false);
};

console.log("on payment isFormValid in Invoice:", props.isFormValid);

return (
  <div>
    {/* id issss: {id}<br />
    uid is: { props.uid}<br />
    valid: {props.isFormValid ? 'true' : 'false'} */}
    {!paymentComplete ? (
      <div className="paymentarea">
        {isLoading && <p>Loading...</p>}
        <div id='payButtonContainer' style={{ position: 'relative' }}>
          <PayPalScriptProvider 
            options={{ 
              "client-id": clientId,
              "currency": "THB" 
            }}
          >
            <PayPalButtons 
              createOrder={initiatePayment}
              onApprove={handleApprove}
              disabled={!props.isFormValid && props.uid === '1'}
            />
          </PayPalScriptProvider>
          {!props.isFormValid && props.uid == '1' && (
            <div className="overlay">
              <p>To proceed with payment,<br />
              Please ensure the registration form is complete and correct.</p>
            </div>
          )}
        </div>
      </div>
    ) : (
      <p className="paymentsuccess">Thank you, your payment is complete!</p>
    )}
  </div>
);


}

export default Payment;