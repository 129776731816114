import react from "react";

function Loader({message}){
    return (
        <div id="loaderArea">
            <svg width="100" height="100">
                <circle cx="50" cy="50" r="45" fill="#aaa" />
                <circle cx="30" cy="50" r="15" fill="gold" >
                    <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    dur="1s"
                    repeatCount="indefinite"
                    />
                </circle>
            </svg>
            <p>{message}</p>
        </div>
    )
}


export default Loader;