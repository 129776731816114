import React, { useState, useEffect } from 'react';
import Invoice from './Invoice';
import { Link } from 'react-router-dom';

function Home(props) {
  const [idFromURL, setidFromURL] = useState('');
  const [totalAmount, setTotalAmount] = useState(300); // Total amount for payment
  const [formRef, setFormRef] = useState(null);


  
  // Fetch ID from URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const idFromURL = searchParams.get('id');
    if (idFromURL) {
      setidFromURL(idFromURL);
    }
  }, []);


  
 

  return (
    <>
      {idFromURL && <Invoice id={idFromURL} setShowHeader={props.setShowHeader}/>}
      {/* <Link to="/register">
        REGISTER NOW
      </Link> */}
    </>
  );
}

export default Home;