import React, { Component, useRef, useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, HashRouter, Routes, Redirect, Link , useHistory} from "react-router-dom"
import Registration from './components/Registration';
import Message from './components/messages/Message';
import UserConfirmed from './components/messages/UserConfirmed';
import Invoice from './components/Invoice';
import Home from './components/Home';


function App() {
  const [headingTitle, setheadingTitle] = useState('Home');
  const [showHeader, setShowHeader] = useState(true);
  return (
    <Router>
      <div className="App">
        {/* <header className="App-header">
          HEADER
        </header> */}
        <Routes>
              {/* <Route path="/" element={<Home headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/>} /> */}
              <Route path="/registration" element={<Registration headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/>} />
              <Route path="/register" element={<Registration headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/>} />
              <Route path="/" element={<Registration headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/>} />
              <Route path="/invoice" element={<Invoice headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/>} />
              <Route path="/pay" element={<Home headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/> } />
              <Route path="/message" element={<Message headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/> } />
              <Route path="/userconfirmed" element={<UserConfirmed headingTitle={headingTitle} setheadingTitle={setheadingTitle} setShowHeader={setShowHeader}/> } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
